import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { getLocaleComma } from '@dx-ui/framework-i18n';
import {
  getEVChargingString,
  getSelfParkingString,
  getTransformedPetFeeText,
  getValetParkingString,
} from '../../../helpers/property-utils';
import Amenities from '../../amenities/amenities';
import type VerticalPolicyTabs from '../../vertical-policy-tabs/VerticalPolicyTabs';
import { useGlobalConfig } from '../../../hooks/use-global-config';

export const AmenitiesPolicy = ({
  amenities,
  brandCode,
}: {
  amenities?: VerticalPolicyTabs['amenities'];
  brandCode?: string;
}) => {
  if (amenities?.length && brandCode) {
    return <Amenities amenities={amenities} brandCode={brandCode} />;
  }
  return null;
};

export const FeesAndChargesPolicy = ({
  feesAndCharges,
}: {
  feesAndCharges: string | undefined;
}) => {
  if (!feesAndCharges) {
    return null;
  }

  return (
    <div className="m-4 lg:m-0">
      <p data-testid="policy-feesAndCharges" className="m-0">
        {feesAndCharges}
      </p>
    </div>
  );
};

export const ParkingPolicy = ({ policy }: { policy: VerticalPolicyTabs['policy'] }) => {
  const { t } = useTranslation('hotel-policies');
  const { isTailored } = useGlobalConfig();
  const language = useRouter().locale || 'en';

  const selfParking = getSelfParkingString(t, policy?.parking);
  const evCharging = getEVChargingString(t, policy?.parking, language);
  const evComments = policy?.parking?.evCharging?.chargingComments;
  const valetParking = getValetParkingString(t, policy?.parking);
  const info = policy?.parking?.otherParkingInfo ? policy?.parking?.otherParkingInfo : null;
  const securedParking = policy?.parking?.hasSecuredParking ? t('available') : t('notAvailable');
  const coveredParking = policy?.parking?.hasCoveredParking ? t('available') : t('notAvailable');
  const inOut = policy?.parking?.hasInOutParkingPrivilege ? t('available') : t('notAvailable');
  const hasSelfParking = policy?.parking?.hasSelfParking;
  const onSiteParking = policy?.parking?.hasOnsiteParking;
  const localeComma = getLocaleComma({ locale: language });

  if (policy?.parking) {
    return (
      <ul className={cx({ 'm-4 lg:m-0': isTailored })}>
        <li
          className={cx('flex flex-row md:mb-6', {
            'lg:mb-4': isTailored,
            'mb-4': !isTailored,
          })}
          data-e2e="policy-parking-self"
          data-testid="policy-parking"
        >
          <div
            className={cx('me-9 flex-1 font-bold', {
              'lg:flex-1/2': !isTailored,
            })}
          >
            {t('selfParking')}
          </div>
          <div className="me-9 flex-1 font-medium">
            {hasSelfParking && onSiteParking ? `${t('onSite')}, ` : null}
            {selfParking}
          </div>
        </li>
        <li
          className="mb-4 flex flex-row md:mb-6"
          data-e2e="policy-parking-valet"
          data-testid="policy-parking-valet"
        >
          <div
            className={cx('me-9 flex-1 font-bold', {
              'lg:flex-1/2': !isTailored,
            })}
          >
            {t('valetParking')}
          </div>
          <div className="me-9 flex-1 font-medium">{valetParking}</div>
        </li>
        <li className="mb-4 flex flex-row md:mb-6" data-testid="policy-parking-evCharging">
          <div
            className={cx('me-9 flex-1 font-bold', {
              'lg:flex-1/2': !isTailored,
            })}
          >
            {t('evCharging')}
          </div>
          <div className="me-9 flex-1 font-medium">
            <p>
              {evCharging}
              {evComments && localeComma}
            </p>
            {evComments ? <p>{evComments}</p> : null}
          </div>
        </li>
        <li
          className="mb-4 flex flex-row md:mb-6"
          data-e2e="policy-parking-secured"
          data-testid="policy-parking-secured"
        >
          <div
            className={cx('me-9 flex-1 font-bold', {
              'lg:flex-1/2': !isTailored,
            })}
          >
            {t('secured')}
          </div>
          <div className="me-9 flex-1 font-medium">{securedParking}</div>
        </li>
        <li
          className="mb-4 flex flex-row md:mb-6"
          data-e2e="policy-parking-covered"
          data-testid="policy-parking-covered"
        >
          <div
            className={cx('me-9 flex-1 font-bold', {
              'lg:flex-1/2': !isTailored,
            })}
          >
            {t('covered')}
          </div>
          <div className="me-9 flex-1 font-medium">{coveredParking}</div>
        </li>
        <li
          className="mb-4 flex flex-row md:mb-6"
          data-e2e="policy-parking-inout"
          data-testid="policy-parking-inout"
        >
          <div
            className={cx('me-9 flex-1 font-bold', {
              'lg:flex-1/2': !isTailored,
            })}
          >
            {t('privileges')}
          </div>
          <div className="me-9 flex-1 font-medium">{inOut}</div>
        </li>
        {info ? (
          <li
            className="mb-4 flex flex-row md:mb-6"
            data-e2e="policy-parking-info"
            data-testid="policy-parking-info"
          >
            <div
              className={cx('me-9 flex-1 font-bold', {
                'lg:flex-1/2': !isTailored,
              })}
            >
              {t('otherParkingInfo')}
            </div>
            <div className="me-9 flex-1 font-medium">{info}</div>
          </li>
        ) : null}
      </ul>
    );
  }
  return null;
};

export const PetsPolicy = ({ petsInfo }: { petsInfo: VerticalPolicyTabs['petsInfo'] }) => {
  const { t } = useTranslation('hotel-policies');
  const { isTailored } = useGlobalConfig();

  const petsAllowed = petsInfo?.petsAllowed;
  const serviceAnimalsOnly = !petsAllowed ? petsInfo?.servicePetsAllowed : null;
  const allowed = petsAllowed ? t('yes') : t('no');
  const nonRefundable =
    !!petsInfo && petsInfo.petCharge && !petsInfo.petChargeRefundable
      ? `${petsInfo.petChargeFmt} ${t('nonRefundableFee')}`
      : null;
  const refundable =
    !!petsInfo && petsInfo.petCharge && !!petsInfo.petChargeRefundable
      ? `. ${petsInfo.petChargeFmt} ${t('refundable')}`
      : null;

  if (serviceAnimalsOnly) {
    return (
      <ul>
        <li
          className="mb-4 flex flex-row md:mb-6"
          data-testid="policy-pets"
          data-e2e="policy-pets-service"
        >
          <div
            className={cx('me-9 flex-1 font-bold', {
              'lg:flex-1/2': !isTailored,
            })}
          >
            {t('serviceAnimals')}
          </div>
          <div className="me-9 flex-1 font-medium">{t('serviceAnimalsOnly')}</div>
        </li>
      </ul>
    );
  }

  if (!!petsInfo && petsAllowed) {
    return (
      <ul className="m-4 lg:m-0">
        <li
          className="mb-4 flex flex-row md:mb-6"
          data-testid="policy-pets"
          data-e2e="policy-pets-allowed"
        >
          <div
            className={cx('me-9 flex-1 font-bold', {
              'lg:flex-1/2': !isTailored,
            })}
          >
            {t('petsAllowed')}
          </div>
          <div className="me-9 flex-1 font-medium">
            {allowed}
            {refundable}
          </div>
        </li>
        {!!petsInfo && nonRefundable ? (
          <li className="mb-4 flex flex-row md:mb-6" data-e2e="policy-pets-norefund">
            <div
              className={cx('me-9 flex-1 font-bold', {
                'lg:flex-1/2': !isTailored,
              })}
            >
              {t('deposit')}
            </div>
            <div className="me-9 flex-1 font-medium">
              {allowed}. {nonRefundable}
            </div>
          </li>
        ) : null}
        {!!petsInfo && petsInfo.petMaxWeightFmt ? (
          <li className="mb-4 flex flex-row md:mb-6" data-e2e="policy-pets-weight">
            <div
              className={cx('me-9 flex-1 font-bold', {
                'lg:flex-1/2': !isTailored,
              })}
            >
              {t('maxWeight')}
            </div>
            <div className="me-9 flex-1 font-medium">{`${petsInfo.petMaxWeightFmt} `}</div>
          </li>
        ) : null}
        {!!petsInfo && petsInfo.petMaxSize ? (
          <li className="mb-4 flex flex-row md:mb-6" data-e2e="policy-pets-size">
            <div
              className={cx('me-9 flex-1 font-bold', {
                'lg:flex-1/2': !isTailored,
              })}
            >
              {t('maxSize')}
            </div>
            <div className="me-9 flex-1 font-medium capitalize">{petsInfo.petMaxSize}</div>
          </li>
        ) : null}
        {!!petsInfo && petsInfo.otherServiceDesc ? (
          <li className="mb-4 flex flex-row md:mb-6" data-e2e="policy-pets-info">
            <div
              className={cx('me-9 flex-1 font-bold', {
                'lg:flex-1/2': !isTailored,
              })}
            >
              {t('otherPetInfo')}
            </div>
            <div className="me-9 flex-1 font-medium">
              {getTransformedPetFeeText(petsInfo.otherServiceDesc)}
            </div>
          </li>
        ) : null}
      </ul>
    );
  }

  return null;
};

export const ResortPolicy = ({ resortPolicyText }: { resortPolicyText: string | null }) => {
  const { t } = useTranslation('hotel-policies');

  return (
    <span className="text-base md:text-lg">
      <div className="mb-4 font-bold md:mb-6">{t('feesAndCharges')}</div>
      <p data-e2e="policy-resort" data-testid="policy-resort" className="m-0">
        {resortPolicyText}
      </p>
    </span>
  );
};

export const SmokingPolicy = ({
  policy,
  safetyInfo,
}: {
  policy: VerticalPolicyTabs['policy'] | null;
  safetyInfo: VerticalPolicyTabs['safetyInfo'];
}) => {
  const { isTailored } = useGlobalConfig();
  const nonSmoking = safetyInfo?.hundredPctNonSmoking;
  const smokingDesc = policy?.smoking?.smokingDesc || safetyInfo?.smokingPolicyDesc;

  if (smokingDesc) {
    return (
      <div className={cx({ 'm-4 lg:m-0': isTailored })}>
        <p
          data-e2e={`policy-smoking-${nonSmoking ? 'no' : 'yes'}`}
          data-testid="policy-smoking"
          className="m-0"
        >
          {smokingDesc}
        </p>
      </div>
    );
  }
  return null;
};

export const WifiPolicy = ({ policy }: { policy: VerticalPolicyTabs['policy'] | null }) => {
  const { isTailored } = useGlobalConfig();
  const { t } = useTranslation('hotel-policies');

  const freeWlanMsg = policy?.wifi?.freeWlanMsg;
  const wlanInRoom = policy?.wifi?.wlanInRoom;
  const wlanInRoomFee = policy?.wifi?.wlanInRoomFee || 0;
  const fee = policy?.wifi?.wlanInRoomFeeFmt;

  const isFreeWifi = wlanInRoom && wlanInRoomFee === 0 && t('freeRoomAndLobbyWiFi');
  const isWifiWithFee =
    wlanInRoom && wlanInRoomFee > 0 && t('standardRoomAndLobbyWiFiWithFee', { fee });

  return (
    <div className={cx({ 'm-4 lg:m-0': isTailored })}>
      <p data-testid="policy-wifi" data-e2e="policy-wifi" className="m-0">
        {freeWlanMsg || isFreeWifi || isWifiWithFee || t('notAvailable')}
      </p>
    </div>
  );
};
